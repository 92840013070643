import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { blueBg, blueBgHover, white } from '../components/colors'
import { signUpFormUrl } from '../components/constants'
import { Note } from '../components/note'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'

const linkSection = css`
  margin: 1.6rem 0 3.2rem;
`
const button = css`
  background-color: ${blueBg};
  color: ${white};
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 21px;
  text-decoration: none;

  &:hover {
    color: ${white};
    background-color: ${blueBgHover};
  }
`

const IndexPage = () => (
  <Layout>
    <Note
        headerText={"We are currently undergoing a rebranding from Deliverr to Flexport."}
        icon={faFileSignature}
    >
      
      </Note>
    <section css={linkSection}>
      <p>
        Learn how to use the Flexport Fast Tags library and let customers know
        that fast, free shipping is available for your storefront. This works with Flexport's Logistics API<br /><br />
      </p>
      <Note
        headerText={"Haven't signed up for our website fast tags program yet?"}
        icon={faFileSignature}
      >
        <div>
          <a
            href={signUpFormUrl}
            css={css`
              font-weight: 400;
            `}
          >
            Sign up and get started by filling out our form
          </a>
        </div>
      </Note>
      <h2>Guide</h2>
      <p>
        Useful for beginners who want to learn how to first integrate fast tags
        on their storefront.
      </p>

      <Link to={'/guide'} css={button}>
        Get Started
      </Link>
    </section>

    <section css={linkSection}>
      <h2>Reference</h2>
      <p>
        Details about Flexport's Web Components and API Client for integration
        into our fast tag system.
      </p>
      <Link to={'/reference'} css={button}>
        Read more
      </Link>
    </section>

    <section css={linkSection}>
      <h2>Still have questions?</h2>
      <p>
        Please visit our{' '}
        <a href="https://support.portal.flexport.com/hc/en-us">help center</a> or{' '}
        <a href="https://support.portal.flexport.com/hc/en-us/requests/new">
          open a ticket
        </a>{' '}
        with our customer support team.
      </p>
    </section>
  </Layout>
)

export default IndexPage
